import { useContext, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Auth } from "../utils/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";

const SingIn = ({ data }) => {
  //  Global State
  const {
    setForm,
    setToken,
    setConfirmRegisterMail,
    setForgetPassowrd,
    setOtp,
    setFormArea,
  } = useContext(Auth);

  const { t ,i18n} = useTranslation();


  //  Local State
  const [visibility, setVisibility] = useState(false);
  const [errorText, setErrorText] = useState("");

  /* ------------------------------ Login Schema ------------------------------ */
  const loginSchema = object({
    email: string().required().trim().email(),
    password: string().required().trim(),
  });

  /* ----------------------------- React Hook Form ---------------------------- */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  /* -------------------------------- Post Data ------------------------------- */
  const onSubmit = async (data) => {
    const body = {
      email: data.email,
      password: data.password,
    };

    await axios
      .post(`${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/auths`, body, {
        crossdomain: true,
      })
      .then((res) => {
        if (res.data.status === "success") {
          localStorage.setItem("token", JSON.stringify(res.data.data.token));
          setToken(res.data.data.token);
          setConfirmRegisterMail("");
          setForgetPassowrd({});
          setOtp("");
          setFormArea(false);
        } else if (res.data.status === "error") {
          setErrorText(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <form
      className={`sing-in  ${data === "sing in" && "active"}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-container">
        <div className="form-group">
          <label>{t("login.email")}</label>
          <input
            type="email"
            name="email"
            className={errors.email && "error"}
            {...register("email", {
              onChange: (e) => {
                errorText !== "" && setErrorText("");
              },
            })}
          />
        </div>

        {errors.email && (
          <div className="error-message">{errors.email.message}</div>
        )}
        <div className="form-group">
          <label>{t("login.password")}</label>
          <input
            type={visibility ? "text" : "password"}
            name="password"
            className={errors.password && "error"}
            {...register("password", {
              onChange: (e) => {
                errorText !== "" && setErrorText("");
              },
            })}
          />
          {visibility ? (
            <AiFillEye
              onClick={() => {
                setVisibility(false);
              }}
            />
          ) : (
            <AiFillEyeInvisible
              onClick={() => {
                setVisibility(true);
              }}
            />
          )}
        </div>
        {errors.password && (
          <div className="error-message">{errors.password.message}</div>
        )}
        {errorText && <div className="error-message">{errorText}</div>}
        <span
          className="forget-text"
          onClick={() => {
            setForm("forgot");
          }}
        >
          {t("login.forget_password")}
        </span>
        <button className="btn"> {t("login.submit")}</button>
      </div>
    </form>
  );
};

export default SingIn;
