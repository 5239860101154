/* ---------------------------- React Router Dom ---------------------------- */
import { Route, Routes, useLocation } from "react-router-dom";

/* --------------------------------- Context -------------------------------- */
import { FormSchema } from "./utils/Auth";

/* ------------------------------- Components ------------------------------- */
import Header from "./components/Header";
import Footer from "./components/Footer";

/* ---------------------------------- Pages --------------------------------- */
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
// import Calendar from "./pages/Calendar";
import Contact from "./pages/Contact";

/* -------------------- Conferance && Training && detail -------------------- */
import Conference from "./pages/Conference";
import Courses from "./pages/Courses";
import ConfranceCourceDetail from "./pages/ConfranceCourceDetail";
import TrainingCategory from "./pages/TrainingCategory";

/* --------------------------------- Spiker --------------------------------- */
import Spikers from "./pages/Spikers";
import SpikerDetail from "./pages/SpikerDetail";

/* -------------------------------- Partners -------------------------------- */
import Partners from "./pages/Partners";
import PartnerDetail from "./pages/PartnerDetail";

/* ------------------------------- Innovation ------------------------------- */
import InnovationsNews from "./pages/InnovationsNews";
import InnovationDetail from "./pages/InnovationDetail";
import CalendarV2 from "./pages/CalendarV2";
import Profile from "./pages/Profile";

// Context
import { AppContext } from "./utils/MainContext";

// Additional Page
import Faq from "./pages/Faq";
import { useEffect } from "react";
import Privacy from "./pages/Privacy";
import ReactGA from "react-ga4";
import TermsOfUse from "./pages/TermsOfUse";
import Agreement from "./pages/Agreement";
import Subscribe from "./pages/partners/Subscribe";
import PartnersInvoice from "./pages/partners/PartnersInvoice";
// import Iscooming from "./pages/Iscooming";

ReactGA.initialize("G-DD7QMKZW2D");

const App = () => {
  // useEffect(() => {
  //   // Google Analytics izleme kodunu ekleyin
  //   const script = document.createElement("script");
  //   script.src = "https://www.googletagmanager.com/gtag/js?id=G-W01T0F39TB";
  //   script.async = true;
  //   document.head.appendChild(script);

  //   const inlineScript = document.createElement("script");
  //   inlineScript.innerHTML = `
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag(){dataLayer.push(arguments);}
  //     gtag('js', new Date());
  //     gtag('config', 'G-W01T0F39TB');
  //   `;
  //   document.head.appendChild(inlineScript);

  //   // Cleanup script when component unmounts (optional)
  //   return () => {
  //     document.head.removeChild(script);
  //     document.head.removeChild(inlineScript);
  //   };
  // }, []);
  const location = useLocation();
  useEffect(() => {
    let locationPath = location.pathname.split("/")[1];
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title:
        location.pathname === "/"
          ? "Home"
          : locationPath === "calendar"
          ? "Calendar Page"
          : locationPath === "conference"
          ? "Conference List Page"
          : locationPath === "courses"
          ? "Courses List Page"
          : locationPath === "general-detail"
          ? " Conference Or Courses Detail Page"
          : locationPath === "spikers"
          ? "Spikers Page"
          : locationPath === "spiker-detail"
          ? "Spikers Detail Page"
          : locationPath === "partners"
          ? "Partners  Page"
          : locationPath === "partner-detail"
          ? "Partners Detail Page"
          : locationPath === "innovatons"
          ? "Innovatons  Page"
          : locationPath === "innovaton-detail"
          ? "Innovatons Detail Page"
          : locationPath === "profile"
          ? "Profile"
          : locationPath === "faq"
          ? "Faq"
          : locationPath === "privacy"
          ? "Privacy"
          : locationPath === "terms-of-use"
          ? "Terms-of-use"
          : locationPath === "agreement"
          ? "Agreement"
          : locationPath === "subscribe"
          ? "Subscribe"
          : locationPath === "partner-invoice"
          ? "Partner Invoice"
          : "Contact",
    });
  }, [location]);
  return (
    <FormSchema>
      <AppContext>
        <Header />
        <main>
          <Routes>
            {/* <Route path="/" element={<Iscooming />} /> */}

            <Route path="/" element={<Home />} />
            {/* Calendar */}
            {/* <Route path="/calendar" element={<Calendar />} /> */}
            <Route path="/calendar" element={<CalendarV2 />} />

            {/* Conferance && Training && detail */}
            <Route path="/conference" element={<Conference />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/training-category" element={<TrainingCategory />} />
            <Route
              path="/general-detail/:type/:id"
              element={<ConfranceCourceDetail />}
            />

            {/*  Spiker */}
            <Route path="/spikers" element={<Spikers />} />
            <Route
              path="/spiker-detail/:speakerId"
              element={<SpikerDetail />}
            />

            {/* Partners */}
            <Route path="/partners" element={<Partners />} />
            <Route
              path="/partner-detail/:partnerId"
              element={<PartnerDetail />}
            />

            {/* Innovation  */}
            <Route path="/innovatons" element={<InnovationsNews />} />
            <Route
              path="/innovaton-detail/:id"
              element={<InnovationDetail />}
            />

            {/* Profil */}
            <Route path="/profile" element={<Profile />} />

            {/* Faq */}
            <Route path="/faq" element={<Faq />} />

            {/* Policy */}
            <Route path="/privacy" element={<Privacy />} />

            {/* Terms-of-use */}
            <Route path="/terms-of-use" element={<TermsOfUse />} />

            {/* Agreement */}
            <Route path="/agreement" element={<Agreement />} />

            {/* Partners Subscribe */}
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/partner-invoice" element={<PartnersInvoice />} />

            {/* Contact */}
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        <Footer />
      </AppContext>
    </FormSchema>
  );
};

export default App;
