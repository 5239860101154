import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Auth } from "../../utils/Auth";

// Icon
import arrow from "../../assets/img/icon/arrow-down-small.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { notify } from "../../utils/ToastIfyNonfoction";

const PersonalDataEdit = () => {
  // Global State
  const {
    token,
    changePassVisible,
    setChangePassVisible,
    selectVisible,
    setSelectVisible,
    logOut,
    setProfilData,
  } = useContext(Auth);

  // Local State
  const [specialityData, setSpecialityData] = useState([]);
  const [workplaceData, setWorkplaceData] = useState([]);
  const [selectSpeciality, setSelectSpeciality] = useState(null);
  const [selectWorkplace, setSelectWorkplace] = useState(null);
  const [loading, setLoading] = useState(false);

  // Language
  const { t, i18n } = useTranslation();

  // Navigate
  const navigate = useNavigate();

  // Get Request For  Speciality
  const getSpeciality = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/dds/ddlist?type=6`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setSpecialityData(res.data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language]);

  // Get Request For  Workplace
  const getWorkplace = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/dds/ddlist?type=7`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setWorkplaceData(res.data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language]);

  // Rendering
  useEffect(() => {
    setLoading(true);
    getSpeciality();
    getWorkplace();
  }, [getSpeciality, getWorkplace]);

  /* --------------------------- General Info Check --------------------------- */
  const profileSchema = object({
    name: string().required().trim(),
    tel: string().required().trim(),
    date: string().required().trim(),
    seriaNumber: string().required().trim(),
    speciality: string().required().trim(),
    workplace: string().required().trim(),
  });

  // Recat Hook Form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileSchema),
  });

  // Get Request For Profile Data
  const getProfile = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/users/profile?token=${token}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setValue("name", res.data.payload.fullname);
          setValue("tel", res.data.payload.phone);
          setValue("seriaNumber", res.data.payload.idcard);
          setValue("date", res.data.payload.birthdate);

          if (Number(res.data.payload.speciality) !== 6) {
            specialityData.filter((speciality) => {
              if (speciality.id === Number(res.data.payload.speciality)) {
                setSelectSpeciality(speciality);
                setValue("speciality", speciality.name);
              }
              return true;
            });
          }
          if (Number(res.data.payload.workplace) !== 7) {
            workplaceData.filter((workplace) => {
              if (workplace.id === Number(res.data.payload.workplace)) {
                setSelectWorkplace(workplace);
                setValue("workplace", workplace.name);
              }
              return TransformStreamDefaultController;
            });
          }
          setTimeout(() => setLoading(false), 800);
        } else if (res.data.message === "Token expired!") {
          setLoading(false);
          navigate("/error");
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          navigate("/error");
        }
      });
  }, [i18n.language, navigate, setValue, specialityData, token, workplaceData]);

  // const getProfile = useCallback(async () => {
  //   setLoading(true);
  //   await axios
  //     .get(
  //       `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/users/profile?token=${token}`,
  //       {
  //         crossdomain: true,
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.status === "success") {
  //         if (res.data.payload) {
  //           setValue("name", res.data.payload.fullname);
  //           setValue("tel", res.data.payload.phone);
  //           setValue("seriaNumber", res.data.payload.idcard);
  //           setValue("date", res.data.payload.birthdate);

  //           if (Number(res.data.payload.speciality) !== 6) {
  //             specialityData.filter((speciality) => {
  //               if (speciality.id === Number(res.data.payload.speciality)) {
  //                 setSelectSpeciality(speciality);
  //                 setValue("speciality", speciality.name);
  //               }
  //               return true;
  //             });
  //           }
  //           if (Number(res.data.payload.workplace) !== 7) {
  //             workplaceData.filter((workplace) => {
  //               if (workplace.id === Number(res.data.payload.workplace)) {
  //                 setSelectWorkplace(workplace);
  //                 setValue("workplace", workplace.name);
  //               }
  //               return TransformStreamDefaultController;
  //             });
  //           }
  //           setTimeout(() => setLoading(false), 800);
  //         } else {
  //           setLoading(false)
  //           return true;
  //         }
  //       } else if (res.data.message === "Token expired!") {
  //         setLoading(false);
  //         navigate("/error");
  //       }
  //     })
  //     .catch((err) => {
  //       if (err) {
  //         setLoading(false);
  //         navigate("/error");
  //       }
  //     });
  // }, [i18n.language, navigate, setValue, specialityData, token, workplaceData]);

  useEffect(() => {
    if (token && workplaceData.length !== 0 && specialityData.length !== 0) {
      getProfile();
    }
  }, [
    getProfile,
    i18n.language,
    navigate,
    specialityData.length,
    token,
    workplaceData.length,
  ]);

  const submitProfile = async (data) => {
    const body = {
      fullname: data.name,
      birthdate: data.date,
      idcard: data.seriaNumber,
      speciality: String(selectSpeciality.id),
      phone: data.tel,
      workplace: String(selectWorkplace.id),
      type: 4,
    };

    await axios
      .post(
        `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/users/updateprofile?token=${token}`,
        body
      )
      .then((res) => {
        if (res.data.status === "success") {
          notify(t("persona_information.success_process"));
          setProfilData(body);
        } else if (res.data.message === "Token expired!") {
          setLoading(false);
          navigate("/error");
        } else {
          notify(t("persona_information.failed_process"));
        }
      })
      .catch((err) => {
        if (err) {
          logOut();
          navigate("/");
        }
      });
  };

  return (
    <>
      {loading && <Loading />}
      <form className="profil-form" onSubmit={handleSubmit(submitProfile)}>
        <div className="inp-area">
          {/* Fullname */}
          <fieldset className="form-group">
            <legend className="caption">
              {t("persona_information.name")} {t("persona_information.surname")}
              :
            </legend>
            <input
              type="text"
              name="name"
              {...register("name")}
              autoComplete="on"
            />
            {errors.name && (
              <div className="error-message">{errors.name.message}</div>
            )}
          </fieldset>
          {/* Phone */}
          <fieldset className="form-group">
            <legend className="caption">
              {t("persona_information.phone")}:
            </legend>
            <input
              type="tel"
              name="tel"
              {...register("tel")}
              autoComplete="on"
            />
            {errors.tel && (
              <div className="error-message">{errors.tel.message}</div>
            )}
          </fieldset>
          {/* seriaNumber */}
          <fieldset className="form-group">
            <legend
              className={
                watch("seriaNumber") === undefined ||
                watch("seriaNumber") === ""
                  ? "label"
                  : "label animate"
              }
            >
              {t("persona_information.seria_code")}:
            </legend>
            <input
              type="text"
              name="seriaNumber"
              {...register("seriaNumber")}
            />
            {errors.seriaNumber && (
              <div className="error-message">{errors.seriaNumber.message}</div>
            )}
          </fieldset>
          {/* birthday */}
          <fieldset className="form-group">
            <legend className="caption">
              {t("persona_information.birthday")}:
            </legend>
            <input
              type="date"
              name="date"
              {...register("date")}
              autoComplete="on"
            />
            {errors.date && (
              <div className="error-message">{errors.date.message}</div>
            )}
          </fieldset>

          {/* speciality */}
          <fieldset className="form-group select-group">
            <legend className="caption">
              {t("persona_information.speciality")}:
            </legend>
            <div
              className="select"
              onClick={() =>
                selectVisible === "speciality"
                  ? setSelectVisible("")
                  : setSelectVisible("speciality")
              }
            >
              <input
                readOnly
                className="text"
                name="speciality"
                {...register("speciality")}
              />
              <div className="icon">
                <img src={arrow} alt="icon" />
              </div>
            </div>
            <div
              className={
                selectVisible === "speciality"
                  ? "select-text active "
                  : "select-text "
              }
            >
              <ul className="select-list scroll">
                <li
                  className="select-item"
                  onClick={() => {
                    setSelectSpeciality({ id: "946" });
                    setValue("speciality", t("general.other"));
                  }}
                >
                 {t("general.other")}
                </li>
                {specialityData.length !== 0 ? (
                  specialityData.map((speciality) => (
                    <li
                      className="select-item"
                      key={speciality.id}
                      onClick={() => {
                        setSelectSpeciality(speciality);
                        setValue("speciality", speciality.name);
                      }}
                    >
                      {speciality.name}
                    </li>
                  ))
                ) : (
                  <li className="select-item">
                    {t("persona_information.not_found")}
                  </li>
                )}
              </ul>
            </div>
            {errors.speciality && (
              <div className="error-message">{errors.speciality.message}</div>
            )}
          </fieldset>

          {/* workplace*/}
          <fieldset className="form-group select-group">
            <legend className="caption">
              {" "}
              {t("persona_information.workplace")}:
            </legend>
            <div
              className="select"
              onClick={() =>
                selectVisible === "workplace"
                  ? setSelectVisible("")
                  : setSelectVisible("workplace")
              }
            >
              <input
                readOnly
                className="text"
                name="workplace"
                {...register("workplace")}
              />
              <div className="icon">
                <img src={arrow} alt="icon" />
              </div>
            </div>
            <div
              className={
                selectVisible === "workplace"
                  ? "select-text active"
                  : "select-text"
              }
            >
              <ul className="select-list scroll">
              <li
                      className="select-item"
                      onClick={() => {
                        setSelectWorkplace({id:"947"});
                        setValue("workplace", t("general.other"));
                      }}
                    >
                   {t("general.other")}
                    </li>


                {workplaceData.length !== 0 ? (
                  workplaceData.map((speciality) => (
                    <li
                      className="select-item"
                      key={speciality.id}
                      onClick={() => {
                        setSelectWorkplace(speciality);
                        setValue("workplace", speciality.name);
                      }}
                    >
                      {speciality.name}
                    </li>
                  ))
                ) : (
                  <li className="select-item">
                    {t("persona_information.not_found")}
                  </li>
                )}
              </ul>
            </div>
            {errors.workplace && (
              <div className="error-message">{errors.workplace.message}</div>
            )}
          </fieldset>
        </div>
        <div className="btn-area">
          <button className="btn"> {t("persona_information.submit")}</button>
          {/* <span
            className="btn"
            onClick={() => setChangePassVisible(!changePassVisible)}
          >
            {t("persona_information.password_update")}
          </span> */}
        </div>
      </form>
    </>
  );
};

export default PersonalDataEdit;
