// React
import React, { useCallback, useContext, useEffect, useState } from "react";

// React Router Dom
import { useLocation, useNavigate } from "react-router-dom";

// Context
import { MainContext } from "../utils/MainContext";

// Axios
import axios from "axios";

// Component
import Card from "../components/Card";
import CardLoader from "../components/CardLoader";
import ConferanceCourseSideBar from "./confrance-cource-detail/ConferanceCourseSideBar";
import CourseConferanceSearchFilterArea from "./confrance-cource-detail/CourseConferanceSearchFilterArea";
import { useTranslation } from "react-i18next";

const Courses = () => {
  // Global State
  const {
    filterMenu,
    setFIlterMenu,
    selectSubCategoryId,
    setCategoryVisible,
    renderType,
    setRenderType,
    searchValue,
  } = useContext(MainContext);

  // Local State
  const [prevNumber, setPrevNumber] = useState(0);
  const [prevNumberDisabled, setPrevNumberDisabled] = useState(false);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);

  // Data
  const [courceData, setCourseData] = useState(["loading"]);
  const [headerData, setHeaderData] = useState("");

  // Navigate
  const navigate = useNavigate();

  // React Router Dom
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, setRenderType]);

  const { t, i18n } = useTranslation();

  // Get Course Data
  const getCourseData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/courses?page=${page_number}&per-page=12`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          setHeaderData(res.headers[`x-pagination-page-count`]);
          // setCourseData((prev) => [...prev, ...res.data]);
          setCourseData(res.data);
        })
        .catch((err) => {
          if (err) {
            navigate("/error");
          }
        });
    },
    [i18n.language, navigate]
  );

  // Get Data Category
  const getCourseCategoryData = useCallback(
    async (page_number) => {
      setCourseData(["loading"]);
      await axios
        .get(
          `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/courses/subcategory?page=${page_number}&per-page=12&id=${selectSubCategoryId.id}`,
          {
            crossdomain: true,
          }
        )
        .then((res) => {
          setHeaderData(res.headers[`x-pagination-page-count`]);
          // setCourseData((prev) => [...prev, ...res.data]);
          setCourseData(res.data);
        })
        .catch((err) => {
          if (err) {
            navigate("/error");
          }
        });
    },
    [i18n.language, navigate, selectSubCategoryId]
  );

  // Seach Data Request
  const searchData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_BACK_DOMAIN}/az/v3/courses?filter[name][like]=${searchValue}&page=${page_number}&per-page=12`
        )
        .then((res) => {
          setHeaderData(res.headers[`x-pagination-page-count`]);
          // setCourseData((prev) => [...prev, ...res.data]);
          setCourseData(res.data);
        })
        .catch((err) => {
          if (err) {
            navigate("/error");
          }
        });
    },
    [navigate, searchValue]
  );

  //  Render Data
  useEffect(() => {
    if (i18n.language) {
      setCourseData(["loading"]);
      if (renderType === "") {
        getCourseData(1);
      } else if (renderType === "category") {
        getCourseCategoryData(1);
      } else if (renderType === "status") {
      } else if (renderType === "search") {
        searchData(1);
      } else {
        return true;
      }
      setHeaderData("");
      setPrevNumber(0);
      setPrevNumberDisabled(false);
      setActiveNumber(1);
      setNextNumber(2);
    }
  }, [
    getCourseCategoryData,
    getCourseData,
    i18n.language,
    renderType,
    searchData,
  ]);

  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (prevNumber === 1) {
      setPrevNumberDisabled(false);
    } else {
      setPrevNumberDisabled(true);
    }
    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }
    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    setCourseData(["loading"]);

    if (renderType === "") {
      getCourseData(newActiveNumber);
    } else if (renderType === "category") {
      getCourseCategoryData(newActiveNumber);
    } else if (renderType === "status") {
    } else if (renderType === "search") {
      searchData(newActiveNumber);
    } else {
      return true;
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="cource-confrance">
      {/* Search Filter */}
      <CourseConferanceSearchFilterArea />

      {/* Card Render Area */}
      <div className="cource-confrance-data">
        <div className="container">
          <h6 className="category-name">
            {t("header.courses")}
            {renderType === "category" ? `/ ${selectSubCategoryId.name}` : ""}
          </h6>
          <div className="category-data">
            {courceData[0] === "loading" ? (
              Array.from({ length: 12 }, (_, index) => (
                <CardLoader key={index} />
              ))
            ) : courceData.length === 0 ? (
              <p className="not-found-text">
                {i18n.language !== "en" && `"${searchValue}"`}{" "}
                {t("alert.not_found_text")}{" "}
                {i18n.language === "en" && `"${searchValue}"`}
              </p>
            ) : (
              courceData.map((course) => (
                <Card data={course} key={course.id} type={"cource"} />
              ))
            )}
          </div>

          {courceData.length !== 0 && (
            <div className="pagination">
              <button
                className="btn prev"
                onClick={() => handleChange("prev")}
                disabled={!prevNumberDisabled}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                    fill="#0046AC"
                  />
                </svg>
              </button>
              {prevNumber !== 0 && (
                <button
                  className="btn prev-number"
                  onClick={() => handleChange("prev")}
                >
                  {prevNumber}
                </button>
              )}
              <button className="btn active">{activeNumber}</button>
              {Number(headerData) > nextNumber && (
                <button
                  className="btn next-number"
                  onClick={() => handleChange("next")}
                >
                  {nextNumber}
                </button>
              )}

              <button
                className="btn next"
                onClick={() => handleChange("next")}
                disabled={!(Number(headerData) > nextNumber)}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                    fill="#0046AC"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* SideBar */}
      <ConferanceCourseSideBar />
      {/* Close SideBar Menu */}
      <div
        className={filterMenu ? "overlay active " : "overlay"}
        onClick={() => {
          if (filterMenu) {
            setFIlterMenu(false);
            setCategoryVisible(false);
          }
        }}
      ></div>
    </section>
  );
};

export default Courses;
