// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Router Dom
import { useNavigate, useParams } from "react-router-dom";

// Axios
import axios from "axios";

// Context
import { Auth } from "../utils/Auth";
import { MainContext } from "../utils/MainContext";

// Langugae
import { useTranslation } from "react-i18next";

// Sections
import DetailBanner from "./confrance-cource-detail/DetailBanner";
import GeneralInfo from "./confrance-cource-detail/GeneralInfo";
import MainInfo from "./confrance-cource-detail/MainInfo";
import ConfranceCourseSpikers from "./confrance-cource-detail/ConfranceCourseSpikers";
import Program from "./confrance-cource-detail/Program";
import ChoosePacket from "./confrance-cource-detail/ChoosePacket";

// Components
import Loading from "../components/Loading";
import Payment from "../components/Payment";
import Ticket from "../components/Ticket";

const ConfranceCourceDetail = () => {
  // Global State
  const { token, profilData } = useContext(Auth);
  const {
    courseConferanceData,
    setCourseConferanceData,
    paymentVisible,
    setPaymentVisible,
    ticketVisible,
    setTicketVisible,
  } = useContext(MainContext);
  // Local State
  // const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Params
  const { type, id } = useParams();

  // Naviagte
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const getConferanceOrCourseData = useCallback(async () => {
    setLoading(true);
    setPaymentVisible(false);
    setTicketVisible(false);
    await axios
      .get(
        `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/${type}/${id}?${
          token && "token=" + token
        }`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setLoading(false);
        setCourseConferanceData(res.data);
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          navigate("/error");
        }
      });
  }, [
    i18n.language,
    id,
    navigate,
    setCourseConferanceData,
    setPaymentVisible,
    setTicketVisible,
    token,
    type,
  ]);




  // Request For Render Data
  useEffect(() => {
    if (type && id) {
      getConferanceOrCourseData();
    }
  }, [getConferanceOrCourseData, id, type, token]);

  return (
    <>
      {loading && <Loading />}
      {courseConferanceData && (
        <>
          <DetailBanner data={courseConferanceData} type={type} />
          <GeneralInfo data={courseConferanceData} type={type} />
          <MainInfo data={courseConferanceData} />
          {courseConferanceData.speakers.length !== 0 && (
            <ConfranceCourseSpikers data={courseConferanceData.speakers} />
          )}
          {courseConferanceData.diary && (
            <Program data={courseConferanceData} type={type}  />
          )}
          {courseConferanceData.prices !== "None" &&
            courseConferanceData.status === 0 && <ChoosePacket />}

          {paymentVisible && <Payment />}
          {ticketVisible && (
            <Ticket
              data={token && profilData && courseConferanceData}
              participationType={courseConferanceData.participant.type}
              type={type}
              eventStartDate={courseConferanceData.startdate}
              eventLocation={`${courseConferanceData.region} / ${courseConferanceData.location}`}
              eventPrice={courseConferanceData?.prices.find(
                (price) =>
                  price.name === courseConferanceData.participant.type &&
                  price.price
              )}
              eventHost={courseConferanceData.partner.name}
              eventPhone={courseConferanceData.partner.phone}
              eventMail={courseConferanceData.partner.email}
              eventName={courseConferanceData.name}
            />
          )}
        </>
      )}
    </>
  );
};

export default ConfranceCourceDetail;
