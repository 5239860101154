import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
const Partners = () => {
  // Local State
  const [partnerData, setPartnerData] = useState(["loading"]);
  const [renderType, setRenderType] = useState("all-data");
  const [prevNumber, setPrevNumber] = useState(0);
  const [prevNumberDisabled, setPrevNumberDisabled] = useState(false);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);
  const [headerData, setHeaderData] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const { t, i18n } = useTranslation();
  // Navigate
  const navigate = useNavigate();

  // Get Partner Data
  const getPartnerData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/partners?page=${page_number}&per-page=12&sort=title`,
          {
            crossdomain: true,
            WithExposedHeaders: true,
          }
        )
        .then((res) => {
          setPartnerData(res.data);
          setHeaderData(res.headers[`x-pagination-page-count`]);
        })
        .catch((err) => {
          if (err) {
            navigate("/error");
          }
        });
    },
    [i18n.language, navigate]
  );

  // Seach Data Request
  const searchData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_BACK_DOMAIN}/az/v3/partners?filter[name][like]=${searchValue}&page=${page_number}&per-page=12`
        )
        .then((res) => {
          setPartnerData(res.data);
          setHeaderData(res.headers[`x-pagination-page-count`]);
        })
        .catch((err) => {
          if (err) {
            console.log(err);
          }
        });
    },
    [searchValue]
  );
  //  Render Data
  useEffect(() => {
    if (i18n.language) {
      setPartnerData(["loading"]);
      if (renderType === "all-data") {
        getPartnerData(1);
      } else if (renderType === "search") {
        searchData(1);
      }
      setHeaderData("");
      setPrevNumber(0);
      setPrevNumberDisabled(false);
      setActiveNumber(1);
      setNextNumber(2);
    }
  }, [getPartnerData, i18n.language, renderType, searchData]);

  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (prevNumber === 1) {
      setPrevNumberDisabled(false);
    } else {
      setPrevNumberDisabled(true);
    }
    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }
    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    setPartnerData(["loading"]);

    if (renderType === "all-data") {
      getPartnerData(newActiveNumber);
    } else if (renderType === "search") {
      searchData(newActiveNumber);
    } else {
      return true;
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <section className="detail-search-area">
        <div className="container">
          <div className="row">
            <div className="info">
              <p className="title">{t("general.parners-caption_primary")}</p>
              <span className="subtitle">
                {t("general.parners-caption_secondary")}
              </span>
              <form className="search">
                <div className="seacrh-icon">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5718 21.4972C16.8172 21.4972 21.0695 17.245 21.0695 11.9996C21.0695 6.75418 16.8172 2.50195 11.5718 2.50195C6.32645 2.50195 2.07422 6.75418 2.07422 11.9996C2.07422 17.245 6.32645 21.4972 11.5718 21.4972Z"
                      stroke="#001D48"
                      strokeWidth="1.49962"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22.0703 22.4976L20.0703 20.4976"
                      stroke="#001D48"
                      strokeWidth="1.49962"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  name="search"
                  onChange={(e) => {
                    if (e.target.value.length > 3) {
                      setSearchValue(e.target.value);
                      setRenderType("search");
                    } else {
                      setRenderType("all-data");
                    }
                  }}
                  className="seacrh-inp"
                  placeholder={t("general.parners_search")}
                />
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="partners">
        <div className="container">
          <h6 className="caption">{t("header.partners")}</h6>
          <div className="partners-area">
            {partnerData[0] === "loading" ? (
              Array.from({ length: 12 }, (_, index) => (
                <div className="partner-card-load " key={index}></div>
              ))
            ) : partnerData.length !== 0 ? (
              partnerData.map((partner) => (
                <Link
                  to={`/partner-detail/${partner.id}`}
                  className="partner-card"
                  key={partner.id}
                >
                  <div className="image-box">
                    <img
                      src={`${process.env.REACT_APP_IMG}/uploads/partners/${partner.image}`}
                      alt={partner.name}
                    />
                  </div>
                </Link>
              ))
            ) : (
              <p className="alert">Not Found Data</p>
            )}
          </div>
          {partnerData.length !== 0 && (
            <div className="pagination">
              <button
                className="btn prev"
                onClick={() => handleChange("prev")}
                disabled={!prevNumberDisabled}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                    fill="#0046AC"
                  />
                </svg>
              </button>
              {prevNumber !== 0 && (
                <button
                  className="btn prev-number"
                  onClick={() => handleChange("prev")}
                >
                  {prevNumber}
                </button>
              )}
              <button className="btn active">{activeNumber}</button>
              {Number(headerData) > nextNumber && (
                <button
                  className="btn next-number"
                  onClick={() => handleChange("next")}
                >
                  {nextNumber}
                </button>
              )}

              <button
                className="btn next"
                onClick={() => handleChange("next")}
                disabled={!(Number(headerData) > nextNumber)}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                    fill="#0046AC"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Partners;
