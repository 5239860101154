import { useContext, useEffect, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { Auth } from "../utils/Auth";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { notify } from "../utils/ToastIfyNonfoction";
import { infoAlert } from "../utils/SweetAlert";

const ChangePassword = () => {
  // Global Statet
  const { otp, setForm, form } = useContext(Auth);

  /* ------------------------------- Local State ------------------------------ */
  const [visibilityFirst, setVisibilityFirst] = useState(false);
  const [visibilitySecond, setVisibilitySecond] = useState(false);
  const [samePassError, setSamePassError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t, i18n } = useTranslation();

  // REset State
  useEffect(() => {
    if (form) {
      setIsSubmitting(false);
    }
  }, [form]);

  // Yup SChema
  const passwordSchema = object({
    password: string().required().trim().min(8).max(18),
    repassword: string().required().trim().min(8).max(18),
  });

  // React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  // Send Request For Change Password
  const onSubmit = async (data) => {
    if (data.password === data.repassword) {
      if (!isSubmitting) {
        setIsSubmitting(true);
        const body = {
          password: data.password,
        };

        await axios
          .post(
            `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/users/passwordnew?token=${otp}`,
            body,
            {
              crossdomain: true,
            }
          )
          .then((res) => {
            setIsSubmitting(false);
            if (res.data.status === "success") {
              setForm("sing in");
              notify(t("change_password.change_succes"));
            } else {
              infoAlert(
                t("general.failed_caption"),
                t("general.failed_detail")
              );
            }
          })
          .catch((err) => err && setIsSubmitting(false));
      }
    } else {
      setSamePassError(true);
    }
  };
  return (
    <div className="change-password-area ">
      <h5 className="change-pass-title">{t("change_password.new_password")}</h5>
      <p className="change-pass-text">{t("change_password.caption")}</p>
      <form
        action=""
        className="change-pass-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="form-group">
          <label> {t("change_password.password")}</label>
          <input
            type={visibilityFirst ? "text" : "password"}
            name="password"
            className={errors.password && "error"}
            {...register("password")}
          />
          {visibilityFirst ? (
            <AiFillEye
              onClick={() => {
                setVisibilityFirst(false);
              }}
            />
          ) : (
            <AiFillEyeInvisible
              onClick={() => {
                setVisibilityFirst(true);
              }}
            />
          )}
          {errors.password && (
            <div className="error-message">{errors.password.message}</div>
          )}
        </div>
        <div className="form-group">
          <label> {t("change_password.repassword")}</label>
          <input
            type={visibilitySecond ? "text" : "password"}
            name="repassword"
            className={errors.repassword && "error"}
            {...register("repassword")}
          />
          {visibilitySecond ? (
            <AiFillEye
              onClick={() => {
                setVisibilitySecond(false);
              }}
            />
          ) : (
            <AiFillEyeInvisible
              onClick={() => {
                setVisibilitySecond(true);
              }}
            />
          )}
          {errors.repassword && (
            <div className="error-message">{errors.repassword.message}</div>
          )}
        </div>
        {samePassError && (
          <div className="error-message">
            {t("change_password.same_password_error")}
          </div>
        )}
        <button className="btn">{t("change_password.password_update")}</button>
      </form>
    </div>
  );
};

export default ChangePassword;
