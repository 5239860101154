import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Calendar from "react-calendar";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
// Icon
import arrow from "../assets/img/icon/arrow-down-small.svg";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import CalendarEventList from "../components/CalendarEventList";
import { MainContext } from "../utils/MainContext";

function CalendarV2() {
  // Global State
  const { listData, setListData } = useContext(MainContext);

  // Local State
  const [events, setEvents] = useState([]);
  const [course, setCourse] = useState([]); // event qalma səbəbi course və konfransi bir başa set edəndə,calendar kitabxanası özündə saxlayıb keşləyir,sıfırlamər
  const [conference, setConference] = useState([]);
  const [select, setSelect] = useState("");
  const [filterType, setFilterType] = useState("course"); //course conference
  const [loading, setLoading] = useState(false);

  // const [events, setEvents] = useState([
  //   {
  //     date: new Date(2023, 10, 10),
  //     title:
  //       "Etkinliksajdbhfjhsajdnfnhsdfbfkjsdbfhkasdasdasdadfasdfadfsdfasdfsdfnsabd ffkasdbf 1",
  //     time: "13:00-15:00",
  //     color: "#1B10EB",
  //   },
  //   {
  //     date: new Date(2023, 10, 10),
  //     title: "Etkinlik 2",
  //     time: "13:00-15:00",
  //     color: "#9BE80C",
  //   },

  // ]);
  const today = new Date();
  const [date, setDate] = useState(today);
  const [monthNumber, setMonthNumber] = useState(0);
  const [dateType, setDateType] = useState("month");
  const [showMoreEvents, setShowMoreEvents] = useState([]);
  const [weekLastDay, setWeekLastDay] = useState(0);
  const [weekFirstDay, setWeekFirstDay] = useState(0);
  const [currentWeekLastDay, setCurrentWeekLastDay] = useState(0);
  const [currentWeekFirstDay, setCurrentWeekFirstDay] = useState(0);
  const [weekRange, setWeekRange] = useState([]);
  const uniqueDates = Array.from(
    new Set(events.map((event) => event.date.toDateString()))
  );
  const colorList = [
    "#00648D",
    "#878900",
    "#008A5C",
    "#5B0366",
    "#800028",
    "#B93E00",
  ];

  // Calendar

  const calendarDesktopType = useRef();

  // Language
  const { i18n, t } = useTranslation();

  // NAvigate
  const navigate = useNavigate();

  // Recat Hook Form
  const { register, setValue, watch } = useForm();

  const getCalendarData = useCallback(async () => {
    setEvents([]);
    setLoading(true);
    setListData([]);
    await axios
      .get(`${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/sites/calendar`, {
        crossdomain: true,
      })
      .then((res) => {
        function getMonthName(monthIndex) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          return months[monthIndex];
        }

        const monthIndex = new Date().getMonth();

        const year = new Date().getFullYear();

        const [filterMonth, filterYear] = [
          String(getMonthName(monthIndex)),
          String(year),
        ];
        res.data.reverse().forEach((event) => {
          const eventData = {
            title: event.name,
            date: new Date(event.startdate.split(" ")[0]),
            time: `${event.startdate.split(" ")[1].slice(0, 5)}-${event.enddate
              .split(" ")[1]
              .slice(0, 5)}`,
            eventEndDate: event.enddate,
            color: colorList[Math.floor(Math.random() * colorList.length)],
            type: event.type,
            id: event.id,
          };

          // Filtered Data
          const eventFilterDate = eventData.date;
          const eventMonth = eventFilterDate.toLocaleString("default", {
            month: "long",
          });
          const eventYear = eventFilterDate.getFullYear().toString();
          if (eventMonth === filterMonth && eventYear === filterYear) {
            if (filterType === "course") {
              setListData((prevData) => [...prevData, eventData]);
            } else {
              setListData((prevData) => [...prevData, eventData]);
            }
          }


          // Course Filterd
          if (event.type === 1) {
            setCourse((prevData) => [...prevData, eventData]);
            setEvents((prevData) => [...prevData, eventData]);
            // setListData((prevData) => {
            //   if (prevData.length < 15) {
            //     return [...prevData, eventData];
            //   }
            //   return prevData;
            // });
          } 
          // Conferance Filtered
          else if (event.type === 2) {
            setConference((prevData) => [...prevData, eventData]);
          }
          setValue("type", t("header.courses"));
          setLoading(false);
        });
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          navigate("/error");
        }
      });
  }, [i18n.language]);

  //   Render Data
  useEffect(() => {
    if (i18n.language) {
      getCalendarData();
    }
  }, [getCalendarData, i18n.language]);

  // Filtered Data for List
  const filteredData = useCallback(
    (monthInfo) => {
      setListData([]);

      const [filterMonth, filterYear] = monthInfo.split(" ");
      if (filterType === "course") {
        const filteredEvents = course.filter((event) => {
          const eventDate = event.date; // Zaten new Date() olarak var
          const eventMonth = eventDate.toLocaleString("default", {
            month: "long",
          });
          const eventYear = eventDate.getFullYear().toString();

          return eventMonth === filterMonth && eventYear === filterYear;
        });

        setListData(filteredEvents);
      } else {
        const filteredEvents = conference.filter((event) => {
          const eventDate = event.date; // Zaten new Date() olarak var
          const eventMonth = eventDate.toLocaleString("default", {
            month: "long",
          });
          const eventYear = eventDate.getFullYear().toString();

          return eventMonth === filterMonth && eventYear === filterYear;
        });

        setListData(filteredEvents);
      }
    },
    [conference, course, filterType, setListData]
  );
  // Change Month Watch
  useEffect(() => {
    const monthChangeButton = document.querySelector(
      ".react-calendar__navigation__label"
    );

    if (monthChangeButton && monthChangeButton.firstChild) {
      // console.log(monthChangeButton.firstChild.textContent); // Değişen metni konsola yazdır

      // MutationObserver oluştur ve dinle
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "childList" ||
            mutation.type === "characterData"
          ) {
            if (dateType === "month") {
              filteredData(monthChangeButton.firstChild.textContent);
            }
          }
        });
      });

      // MutationObserver'ı başlat, alt düğümlerdeki değişiklikleri izle
      observer.observe(monthChangeButton, {
        childList: true, // Çocuk düğümler eklendi veya kaldırıldığında tetiklenir
        subtree: true, // Alt düğümlerdeki değişiklikleri de izler
        characterData: true, // Metin içeriği değişikliklerini de izler
      });

      // Cleanup: bileşen unmount olduğunda observer'ı durdur
      return () => {
        observer.disconnect();
      };
    }
  }, [dateType, filteredData]);

  // Month Change Button Disabled On/OFF
  useEffect(() => {
    const handleResize = () => {
      const monthChangeButton = document.querySelector(
        ".react-calendar__navigation__label"
      );

      if (monthChangeButton) {
        if (window.innerWidth > 768) {
          monthChangeButton.setAttribute("disabled", true);
        } else {
          monthChangeButton.removeAttribute("disabled");
        }
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const weekButton = useRef(null);
  const monthButton = useRef(null);
  let nextMonth = () => {
    if (weekLastDay + 7 > 34) {
      document
        .querySelector(".react-calendar__navigation__next-button")
        .click();
      setWeekFirstDay(0);
      setWeekLastDay(6);
      setTimeout(() => {
        weekRender();
        weekButton.current.click();
      }, 100);
    } else {
      setWeekFirstDay(weekFirstDay + 7);
      setWeekLastDay(weekLastDay + 7);
      setTimeout(() => {
        weekRender();
        weekButton.current.click();
      }, 100);
    }
  };
  let beforeMonth = () => {
    if (weekFirstDay - 7 < -1) {
      document
        .querySelector(".react-calendar__navigation__prev-button")
        .click();
      setWeekFirstDay(28);
      setWeekLastDay(34);
      setTimeout(() => {
        weekRender();
        weekButton.current.click();
      }, 100);
    } else {
      setWeekFirstDay(weekFirstDay - 7);
      setWeekLastDay(weekLastDay - 7);
      // console.log(weekFirstDay);
      // console.log(weekLastDay);
      setTimeout(() => {
        weekRender();
        weekButton.current.click();
      }, 100);
    }
  };

  const handleShowMoreEvents = (date) => {
    setShowMoreEvents([...showMoreEvents, date.toDateString()]);
  };

  const [customClass, setCustomClass] = useState("");
  const handleDayClick = (value) => {
    // Özel sınıfın değerini güncelle
    setCustomClass("active-day");

    // Günü seç
    setDate(value);
  };
  useEffect(() => {
    // Component her render edildiğinde, özel sınıfı temizle
    return () => {
      setCustomClass("active-day");
    };
  }, [date]);

  useEffect(() => {
    const nextButton = document.querySelector(
      ".react-calendar__navigation__next-button"
    );
    const prevButton = document.querySelector(
      ".react-calendar__navigation__prev-button"
    );

    const handleNextButtonClick = () => {
      // console.log("Next button clicked!");
      setMonthNumber((prevMonthNumber) => prevMonthNumber + 1);

      setTimeout(() => {
        const monthInfo = document.querySelector(
          ".react-calendar__navigation__label__labelText--from"
        );
        // filteredData(monthInfo.textContent);
      }, 200);
    };

    const handlePrevButtonClick = () => {
      // console.log("Prev button clicked!");
      setMonthNumber((prevMonthNumber) => prevMonthNumber - 1);

      setTimeout(() => {
        const monthInfo = document.querySelector(
          ".react-calendar__navigation__label__labelText--from"
        );
        // filteredData(monthInfo.textContent);
      }, 200);
    };

    if (nextButton && prevButton) {
      nextButton.addEventListener("click", handleNextButtonClick);
      prevButton.addEventListener("click", handlePrevButtonClick);

      return () => {
        nextButton.removeEventListener("click", handleNextButtonClick);
        prevButton.removeEventListener("click", handlePrevButtonClick);
      };
    }
  }, [events, filteredData]);

  useEffect(() => {
    function getWeekNumberInMonth(date) {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const dayOfWeek = firstDayOfMonth.getDay();
      if (dayOfWeek === 0) {
        return 1;
      }
      if (dayOfWeek === 6) {
        return 5;
      }
      return Math.ceil((date.getDate() + dayOfWeek) / 7);
    }

    const currentDate = new Date();
    let week = getWeekNumberInMonth(currentDate);
    setWeekFirstDay((week - 1) * 7);
    setWeekLastDay(week * 7 - 1);
    setCurrentWeekFirstDay((week - 1) * 7);
    setCurrentWeekLastDay(week * 7 - 1);
  }, []);

  let checkMounth = () => {
    if (monthNumber !== 0) {
      const nextButton = document.querySelector(
        ".react-calendar__navigation__next-button"
      );
      const prevButton = document.querySelector(
        ".react-calendar__navigation__prev-button"
      );

      if (monthNumber > 0) {
        console.log("k");
        for (let i = 0; i < monthNumber; i++) {
          setTimeout(() => {
            prevButton.click();
          }, i * 0.1); // 1000 milliseconds (1 second) delay between clicks, you can adjust this value
        }
      } else if (monthNumber < 0) {
        console.log("b");
        for (let i = 0; i > monthNumber; i--) {
          setTimeout(() => {
            nextButton.click();
          }, Math.abs(i) * 0.1); // 1000 milliseconds (1 second) delay between clicks, you can adjust this value
        }
      }
    }
  };
  // console.log(today.getDate());
  let dayRender = () => {
    weekRender();
    let week = document.querySelectorAll(".react-calendar__tile");
    document.querySelector(".react-calendar__navigation").style.display =
      "none";
    for (let i = 0; i < week.length; i++) {
      // console.log( `${i}:` + week[i].classList);
      week[i].classList.add("week-visible");
      week[i].classList.remove(
        "react-calendar-update-week",
        "react-calendar-update-day"
      );
      if (i >= weekFirstDay && i <= weekLastDay) {
        week[i].classList.remove("week-visible");
        week[i].classList.add(
          "react-calendar-update-week",
          "react-calendar-update-day"
        );
      }
      week[i].classList.forEach((item) => {
        if (item === "react-calendar__tile--now") {
          week[i].classList.remove("react-calendar-update-day");
        }
      });
      // if (week[i].firstChild.textContent === String(today.getDate())) {
      //   console.log(week[i]);
      // }
    }
  };

  let weekRender = () => {
    let week = document.querySelectorAll(".react-calendar__tile");
    document.querySelector(".react-calendar__navigation").style.display =
      "none";
    setWeekRange([]);
    for (let i = 0; i < week.length; i++) {
      week[i].classList.add("week-visible");
      week[i].classList.remove("react-calendar-update-week");
      week[i].setAttribute("disabled", "");

      // Current week render
      if (dateType === "day") {
        if (i >= currentWeekFirstDay && i <= currentWeekLastDay) {
          week[i].classList.remove("week-visible");
          week[i].classList.add("react-calendar-update-week");
          // console.log(week[i])
        }
      }
      // General week render
      else {
        if (i >= weekFirstDay && i <= weekLastDay) {
          week[i].classList.remove("week-visible");
          week[i].classList.add("react-calendar-update-week");
          setWeekRange((prev) => [...prev, week[i].firstChild.ariaLabel]);
          // console.log(week[i].firstChild.ariaLabel);
        }
      }
    }
    const monthInfo = document.querySelector(
      ".react-calendar__navigation__label__labelText--from"
    );
    filteredData(monthInfo.textContent);
  };

  let monthRender = () => {
    document.querySelector(".react-calendar__navigation").style.display =
      "flex";
    let week = document.querySelectorAll(".react-calendar__tile");
    for (let i = 0; i < week.length; i++) {
      week[i].classList.remove("week-visible");
      week[i].classList.remove("react-calendar-update-week");
      week[i].removeAttribute("disabled", "");
    }
    const monthInfo = document.querySelector(
      ".react-calendar__navigation__label__labelText--from"
    );
    filteredData(monthInfo.textContent);
  };

  const handleFilter = (eventType) => {
    if (eventType === "Course" && watch("type") !== "Course") {
      setEvents([]);
      setValue("type", t("header.courses"));
      setFilterType("course");

      setEvents(course);
    }

    if (eventType === "Conference" && watch("type") !== "Conference") {
      setEvents([]);
      setValue("type", t("header.conference"));
      setFilterType("conference");

      setEvents(conference);
    }
    setSelect("");

    const monthInfo = document.querySelector(
      ".react-calendar__navigation__label__labelText--from"
    );

    setListData([]);

    const [filterMonth, filterYear] = monthInfo.textContent.split(" ");

    if (eventType === "Course") {
      const filteredEvents = course.filter((event) => {
        const eventDate = event.date; // Zaten new Date() olarak var
        const eventMonth = eventDate.toLocaleString("default", {
          month: "long",
        });
        const eventYear = eventDate.getFullYear().toString();

        return eventMonth === filterMonth && eventYear === filterYear;
      });

      setListData(filteredEvents);
    } else {
      const filteredEvents = conference.filter((event) => {
        const eventDate = event.date;
        const eventMonth = eventDate.toLocaleString("default", {
          month: "long",
        });
        const eventYear = eventDate.getFullYear().toString();

        return eventMonth === filterMonth && eventYear === filterYear;
      });

      setListData(filteredEvents);
    }
  };
  // Weekly Render ListData
  useEffect(() => {
    if (weekRange && dateType === "week") {
      setListData([]);

      const start = new Date(weekRange[0]);
      const end = new Date(weekRange[6]);

      if (filterType === "course") {
        const filteredEvents = course.filter((event) => {
          const eventDate = new Date(event.date); // Event date is already a Date object

          // Check if the event date is between the start and end dates (inclusive)
          return eventDate >= start && eventDate <= end;
        });

        setListData(filteredEvents);
      } else if (filterType === "conference") {
        const filteredEvents = conference.filter((event) => {
          const eventDate = new Date(event.date); // Event date is already a Date object

          // Check if the event date is between the start and end dates (inclusive)
          return eventDate >= start && eventDate <= end;
        });

        setListData(filteredEvents);
      }
    }
  }, [conference, course, dateType, filterType, setListData, weekRange]);

  return (
    <section className="calendar-area" onClick={() => select && setSelect("")}>
      {loading && <Loading />}
      <div className="container">
        <div className="row">
          <div className="mini-calendar">
            {/* <Calendar /> */}
            <CalendarEventList
              listData={listData}
              course={course}
              conference={conference}
              filterType={filterType}
            />
          </div>
          <div className="main-calendar">
            {/* Filter area */}
            <div className="filter">
              <fieldset className="form-group select-group">
                <legend className="caption">{t("calendar.type")}:</legend>
                <div
                  className="select"
                  onClick={() =>
                    select === "type" ? setSelect("") : setSelect("type")
                  }
                >
                  <input
                    readOnly
                    className="text"
                    name="type"
                    {...register("type")}
                  />
                  <div className="icon">
                    <img src={arrow} alt="icon" />
                  </div>
                </div>
                <div
                  className={
                    select === "type" ? "select-text active " : "select-text "
                  }
                >
                  <ul className="select-list scroll">
                    <li
                      className="select-item"
                      onClick={() => {
                        handleFilter("Course");
                        if (watch("type") !== "Course") {
                          setEvents([]);
                          setValue("type", t("header.courses"));
                          setFilterType("course");

                          setEvents(course);
                        }
                        setSelect("");
                      }}
                    >
                      {t("header.courses")}
                    </li>
                    <li
                      className="select-item"
                      onClick={() => {
                        handleFilter("Conference");
                        if (watch("type") !== "Conference") {
                          setEvents([]);
                          setValue("type", t("header.conference"));
                          setFilterType("conference");

                          setEvents(conference);
                        }
                        setSelect("");
                      }}
                    >
                      {t("header.conference")}
                    </li>
                  </ul>
                </div>
              </fieldset>
            </div>
            <div className="navigation">
              <div className="nav-area">
                <div className="date-type">
                  {/* <button
                    className={dateType === "day" ? "day active" : "day"}
                    onClick={() => {
                      if (monthNumber !== 0) {
                        checkMounth();
                        monthRender();
                      } else {
                        setDateType("day");
                        dayRender();
                        weekRender();
                      }
                    }}
                  >
                    Gün
                  </button> */}
                  <button
                    className={dateType === "week" ? "week active" : "week"}
                    onClick={() => {
                      setDateType("week");
                      weekRender();
                    }}
                    ref={weekButton}
                  >
                    {t("calendar.week")}
                  </button>
                  <button
                    className={dateType === "month" ? "month active" : "month"}
                    onClick={() => {
                      setDateType("month");
                      monthRender();
                    }}
                    ref={monthButton}
                  >
                    {t("calendar.month")}
                  </button>
                </div>
                <div
                  className={
                    dateType === "week"
                      ? "change-week-day active"
                      : "change-week-day"
                  }
                >
                  <span className="text">
                    {/* {t("calendar.week")} */}
                    {weekRange[0]?.split(",")[0]}-{weekRange[6]?.split(",")[0]}
                  </span>
                  <button onClick={() => beforeMonth()}> &lt;</button>
                  <button onClick={() => nextMonth()}>&gt;</button>
                </div>
              </div>
              <Calendar
                locale="en-US"
                onChange={setDate}
                value={date}
                // onClickDay={(value) => handleDayClick(value)}
                // className={`react-calendar ${customClass}`}
                tileClassName={` ${customClass}`}
                tileContent={({ date, view }) => {
                  const dateKey = date.toDateString();
                  const filteredEvents = events.filter(
                    (event) => event.date.toDateString() === dateKey
                  );
                  if (dateType === "month") {
                    if (view === "month" && filteredEvents.length > 0) {
                      if (
                        filteredEvents.length > 2 ||
                        showMoreEvents.includes(date.toDateString())
                      ) {
                        return filteredEvents
                          .slice(0, 2)
                          .map((event, index) => (
                            <div
                              key={event.title}
                              className="event-marker"
                              style={{ backgroundColor: event.color }}
                            >
                              <p className="text">{event.title}</p>
                              <span className="time">{event.time}</span>
                            </div>
                          ))
                          .concat(
                            <div
                              className="event-marker show-more"
                              onClick={() => handleShowMoreEvents(date)}
                            >
                              {t("general.read_more")}
                            </div>
                          );
                      } else {
                        return filteredEvents.map((event, index) => (
                          <div
                            key={event.title}
                            className="event-marker"
                            style={{ backgroundColor: event.color }}
                          >
                            <p className="text">{event.title}</p>
                            <span className="time">{event.time}</span>
                          </div>
                        ));
                      }
                    }
                  } else if (dateType === "week" || dateType === "day") {
                    return filteredEvents.map((event, index) => (
                      <div
                        key={event.title}
                        className="event-marker"
                        style={{ backgroundColor: event.color }}
                      >
                        <p className="text">{event.title}</p>
                        <span className="time">{event.time}</span>
                      </div>
                    ));
                  }

                  return null;
                }}
                ref={calendarDesktopType}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CalendarV2;
