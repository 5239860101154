import { useContext, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { Auth } from "../utils/Auth";
import { useTranslation } from "react-i18next";
import { infoAlert } from "../utils/SweetAlert";

const SingUp = ({ data }) => {
  const { setForm, setConfirmRegisterMail } = useContext(Auth);

  const { t, i18n } = useTranslation();

  /* ------------------------------- Local State ------------------------------ */
  const [visibilityFirst, setVisibilityFirst] = useState(false);
  const [visibilitySecond, setVisibilitySecond] = useState(false);
  const [step, setStep] = useState("one");
  const [errorText, setErrorText] = useState("");

  /* ------------------------------ Login Schema ------------------------------ */
  const persoalInfo = object({
    name: string().required().trim(),
    surname: string().required().trim(),
    email: string().required().trim().email(),
    phone: string().required().trim(),
  });
  const generalInfo = object({
    password: string().required().trim().min(8).max(18),
    repassword: string().required().trim().min(8).max(18),
    // firstCheck: boolean().oneOf(
    //   [true],
    //   "You must accept the terms and conditions"
    // ),
    // secondCheck: boolean().oneOf(
    //   [true],
    //   "You must accept the terms and conditions"
    // ),
  });

  /* ----------------------------- React Hook Form ---------------------------- */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(step === "one" ? persoalInfo : generalInfo),
  });

  //
  const otpToken = async (mail) => {
    await axios
      .get(
        `${process.env.REACT_APP_BACK_DOMAIN}/${i18n.language}/v3/users/newregtoken?email=${mail}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        if (res.data.success === "success") {
          setForm("register-confirm");
        } else {
          infoAlert(t("general.failed_caption"), t("general.failed_detail"));
        }
      })
      .catch((err) => console.log(err));
  };

  /* -------------------------------- Post Data ------------------------------- */
  const onSubmit = async (data) => {
    if (data) {
      setStep("two");
      if (step === "two") {
        const body = {
          email: data.email,
          password: data.password,
          fullnameu: `${data.name}  ${data.surname}`,
          phoneu: data.phone,
        };
console.log(process.env.REACT_APP_SECRET_KEY)
        await axios
          .post(`${process.env.REACT_APP_BACK_DOMAIN}/en/v3/users`, body, {
            crossdomain: true,
            headers: {
              "Signature": process.env.REACT_APP_SECRET_KEY, 
            },
          })
          .then((res) => {
            setConfirmRegisterMail(data.email);
            if (res.data.success === "success") {
              setForm("register-confirm");
            } else if (res.data.message === "This user is not activated") {
              otpToken(data.email);
            } else if (res.data.message === "Duplicate email!") {
              setErrorText(t("register.error_exist"));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };
  return (
    <form
      className={`sing-up  ${data === "sing up" && "active"}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="register-area">
        <div
          className={
            step === "one"
              ? "preliminary-information active"
              : "preliminary-information "
          }
        >
          <div className="form-container">
            <h2 className="title">{t("register.first_info")}</h2>
            <div className="step">
              <p className="info">{t("register.step")} 1/2</p>
            </div>

            <div className="form-group personal">
              <div className="specific-info">
                <label>{t("register.name")}</label>
                <input
                  type="text"
                  name="name"
                  className={errors.name && "error"}
                  {...register("name")}
                />
                {errors.name && (
                  <div className="error-message">{errors.name.message}</div>
                )}
              </div>
              <div className="specific-info">
                <label>{t("register.surname")}</label>
                <input
                  type="text"
                  name="surname"
                  className={errors.surname && "error"}
                  {...register("surname")}
                />
                {errors.surname && (
                  <div className="error-message">{errors.surname.message}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>{t("register.email")}</label>
              <input
                type="email"
                name="email"
                className={errors.email && "error"}
                {...register("")}
                {...register("email", {
                  onChange: (e) => {
                    errorText !== "" && setErrorText("");
                  },
                })}
              />
              {errors.email && (
                <div className="error-message">{errors.email.message}</div>
              )}
            </div>
            <div className="form-group">
              <label>{t("register.phone")}</label>
              <input
                type="tel"
                name="phone"
                className={errors.phone && "error"}
                {...register("phone")}
              />
              {errors.phone && (
                <div className="error-message">{errors.phone.message}</div>
              )}
            </div>
            <button className="btn">{t("register.next")}</button>
          </div>
        </div>
        <div
          className={
            step === "two"
              ? "password-information active"
              : "password-information "
          }
        >
          <div className="form-container">
            <h2 className="title">{t("register.second_info")}</h2>
            <div className="step">
              <p className="info">{t("register.step")} 2/2</p>
              <span className="back" onClick={() => setStep("one")}>
                {t("register.back")}
              </span>
            </div>
            <div className="form-group">
              <label>{t("register.password")}</label>
              <input
                type={visibilityFirst ? "text" : "password"}
                name="password"
                className={errors.password && "error"}
                {...register("password")}
              />
              {visibilityFirst ? (
                <AiFillEye
                  onClick={() => {
                    setVisibilityFirst(false);
                  }}
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => {
                    setVisibilityFirst(true);
                  }}
                />
              )}
              {errors.password && (
                <div className="error-message">{errors.password.message}</div>
              )}
            </div>
            <div className="form-group">
              <label>{t("register.repassword")}</label>
              <input
                type={visibilitySecond ? "text" : "password"}
                name="repassword"
                className={errors.repassword && "error"}
                {...register("repassword")}
              />
              {visibilitySecond ? (
                <AiFillEye
                  onClick={() => {
                    setVisibilitySecond(false);
                  }}
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => {
                    setVisibilitySecond(true);
                  }}
                />
              )}
              {errors.repassword && (
                <div className="error-message">{errors.repassword.message}</div>
              )}
            </div>
  
            {errorText && <div className="error-message">{errorText}</div>}

 

            <button className="btn">{t("register.submit")}</button>
          </div>
        </div>

 
      </div>
    </form>
  );
};

export default SingUp;

// import { useContext, useEffect, useState } from "react";
// import { Auth } from "../utils/Auth";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { object, string } from "yup";
// import "react-phone-input-2/lib/style.css";
// import { parsePhoneNumberFromString } from "libphonenumber-js";
// import countryData from "../db/country";
// import { AiFillCaretDown } from "react-icons/ai";

// const SingUp = ({ data }) => {
//   /* ------------------------------- Local State ------------------------------ */
//   const [phone, setPhone] = useState({
//     numberHead: "+994",
//     flaq: "https://flagcdn.com/w320/az.png",
//   });
//   // const [phone, setPhone] = useState("");
//   const [isValid, setIsValid] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   // const [searchFlaq, setSearchFlaq] = useState("");
//   const [countries, setCountries] = useState([]);
//   /* ------------------------------- Global State ------------------------------ */
//   const { setForm } = useContext(Auth);

//   useEffect(() => {
//     setCountries(countryData);
//   }, []);
//   /* ------------------------------ Login Schema ------------------------------ */
//   const loginSchema = object({
//     phoneNumber: string().required().trim(),
//   });

//   /* ----------------------------- React Hook Form ---------------------------- */
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     formState: { errors },
//   } = useForm({ resolver: yupResolver(loginSchema) });

//   const handlePhoneNumberChange = (event) => {
//     const input = event.target.value;
//     const parsedNumber = parsePhoneNumberFromString(input);

//     if (parsedNumber) {
//       const formattedInput = parsedNumber.formatInternational();

//       // setPhone(formattedInput);
//       setPhone({ ...phone, numberHead: formattedInput });
//       // console.log(formattedInput);
//       // console.log(phone)
//       setValue("phoneNumber", formattedInput);
//       setIsValid(parsedNumber.isValid());
//     } else {
//       setIsValid(false);
//       // setPhone(input);
//       setValue("phoneNumber", input);
//     }
//   };

//   const [upgrade, setUpgrade] = useState([]);
//   const searchNum = (event) => {
//     let input = String(event.target.value);

//     if (input.length < 5 && input.length > 0) {
//       let inp = input.substring(1, input.length);
//       let matchedItems = countryData.filter((item) => item.phone === inp);
//       console.log(matchedItems);
//       // setUpgrade([...upgrade, ...matchedItems]);
//       // setCountries(upgrade);
//       // console.log(countries)
//     }

//     // if (String(input).length < 5 && String(input).length > 0) {
//     //   const inp = input.substring(1, String(input).length);
//     //   const matchedItems = countryData.filter((item) => item.phone === inp);
//     //   setUpgrade([...upgrade, ...matchedItems]);
//     //   setCountries(upgrade);
//     // } else {
//     //   return null;
//     // }
//   };

//   // const searchNum = (event) => {
//   //   const input = event.target.value;
//   //   if (String(input).length < 5 && String(input).length > 1) {
//   //     const inp = input.substring(1, String(input).length);
//   //     countryData.map((item) => {
//   //       if (item.phone === inp) {
//   //         setUpgrade([...upgrade, item]);
//   //       } else {
//   //         return null;
//   //       }
//   //     });
//   //   } else {
//   //     return null;
//   //   }
//   // };

//   /* -------------------------------- Post Data ------------------------------- */
//   const onSubmit = (data) => {
//     console.log(data);
//   };
//   return (
//     <form
//       className={`sing-up  ${data === "sing up" && "active"}`}
//       onSubmit={handleSubmit(onSubmit)}
//     >
//       <div className="form-container">
//         <div className="form-group ">
//           <label>Telefon nömrəsi</label>
//           <div className="phone-number">
//             <ul className="country-box" onClick={() => setIsOpen(!isOpen)}>
//               <div className="img-box">
//                 <img src={phone.flaq} alt="flaq" />
//               </div>
//               <div className="icon">
//                 <AiFillCaretDown />
//               </div>
//             </ul>
//             <ul
//               className={
//                 isOpen ? "country-name-list isShown" : "country-name-list "
//               }
//             >
//               {countries.map((item) => (
//                 <li
//                   className="country-item "
//                   onClick={() => {
//                     setPhone({ numberHead: `+${item.phone}`, flaq: item.flag });
//                     setValue("phoneNumber", `+${item.phone}`);
//                     setIsOpen(false);
//                   }}
//                   key={item.code}
//                 >
//                   <div className="flaq">
//                     <img src={item.flag} alt={item.code} />
//                   </div>
//                   <p className="country-name">{item.label}</p>
//                   <span className="phone-number">+{item.phone}</span>
//                 </li>
//               ))}
//             </ul>
//             <input
//               type="text"
//               defaultValue={phone.numberHead}
//               // onChange={handlePhoneNumberChange}
//               name="phoneNumber"
//               {...register("phoneNumber", {
//                 onChange: (e) => {
//                   handlePhoneNumberChange(e);
//                   searchNum(e);
//                 },
//               })}
//               placeholder="Telefon numarası girin"
//             />
//           </div>
//           {!isValid && <p className="error-message">Geçerli değil.</p>}
//         </div>
//         <button className="btn">Qeydiyyat</button>
//         <p className="text">
//           Artıq hesabınız var?
//           <span
//             className="sing-up-link"
//             onClick={() => {
//               setForm("sing in");
//             }}
//           >
//             Daxil ol
//           </span>
//         </p>
//       </div>
//       <div
//         className={isOpen ? "overlay isOpen" : "overlay"}
//         onClick={() => setIsOpen(false)}
//       ></div>
//     </form>
//   );
// };

// export default SingUp;

// import { useContext, useState } from "react";
// import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
// import { Auth } from "../utils/Auth";
// import { Controller, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { object, string } from "yup";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import startsWith from "lodash.startswith";
// import libphonenumber from "google-libphonenumber";
// import { parsePhoneNumberFromString } from "libphonenumber-js";

// import { useContext, useEffect, useState } from "react";
// import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
// import { Auth } from "../utils/Auth";
// import { Controller, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { object, string } from "yup";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import startsWith from "lodash.startswith";
// import libphonenumber from "google-libphonenumber";
// import { parsePhoneNumberFromString } from "libphonenumber-js";
// import axios from "axios";
// import { keyboard } from "@testing-library/user-event/dist/keyboard";
// import countryData from "../db/country";
// import { AiFillCaretDown } from "react-icons/ai";

// const SingUp = ({ data }) => {
//   /* ------------------------------- Local State ------------------------------ */
//   // const [visibility, setVisibility] = useState(false);
//   // const [country, setCountry] = useState("US");
//   const [phone, setPhone] = useState("");
//   // const [formattedNumber, setFormattedNumber] = useState("");
//   /* ------------------------------- Global State ------------------------------ */
//   const { setForm } = useContext(Auth);

//   /* ------------------------------ Login Schema ------------------------------ */
//   const loginSchema = object({
//     name: string().required().trim(),
//     phoneNumber: string().required().trim(),
//     surname: string().required().trim(),
//     email: string().required().trim().email(),
//     password: string().required().trim().min(8).max(18),
//     repassword: string().required().trim().min(8).max(18),
//   });

//   /* ----------------------------- React Hook Form ---------------------------- */
//   const {
//     register,
//     // control,
//     // setValue,
//     handleSubmit,
//     formState: { errors },
//   } = useForm({ resolver: yupResolver(loginSchema) });

//   // const [phoneNumber, setPhoneNumber] = useState('');

//   // const handlePhoneNumberChange = (event) => {
//   //   const input = event.target.value;
//   //   const parsedNumber = parsePhoneNumberFromString(input);

//   //   if (parsedNumber) {
//   //     const formattedInput = parsedNumber.formatInternational();
//   //     console.log(formattedInput)
//   //     setPhone(formattedInput);
//   //   } else {
//   //     setPhone(input);
//   //   }
//   // };

//   const [isValid, setIsValid] = useState(false);

//   const handlePhoneNumberChange = (event) => {
//     const input = event.target.value;
//     const parsedNumber = parsePhoneNumberFromString(input);

//     if (parsedNumber) {
//       const formattedInput = parsedNumber.formatInternational();
//       setPhone(formattedInput);
//       setIsValid(parsedNumber.isValid());
//     } else {
//       setIsValid(false);
//       setPhone(input);
//     }
//   };
//   const [selectedValue, setSelectedValue] = useState("");
//   console.log(selectedValue)
//   /* -------------------------------- Post Data ------------------------------- */
//   const onSubmit = (data) => {
//     console.log(data);
//     // const { phoneNumber, country } = data;
//     // const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
//     // try {
//     //   const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
//     //   const formattedNumber = phoneUtil.format(
//     //     parsedNumber,
//     //     libphonenumber.PhoneNumberFormat.INTERNATIONAL
//     //   );
//     //   setFormattedNumber(formattedNumber);
//     // } catch (error) {
//     //   console.error("Invalid phone number", error);
//     //   setFormattedNumber("Invalid phone number");
//     // }
//   };
//   return (
//     <form
//       className={`sing-up  ${data === "sing up" && "active"}`}
//       onSubmit={handleSubmit(onSubmit)}
//     >
//       <div className="form-container">
//         <div className="form-group">
//           {/* <PhoneInput
//             country={"az"}
//             inputProps={{
//               name: "phone",
//               required: true,
//               autoFocus: true,
//             }}
//             isValid={(inputNumber, selectedCountry, countries) => {
//               const normalizedInput = inputNumber.replace(/\D/g, ""); // Yalnız rəqəmləri saxlamaq üçün təmizləyirik

//               return countries.some((country) => {
//                 const normalizedDialCode = country.dialCode.replace(/\D/g, ""); // Yalnız rəqəmləri saxlamaq üçün təmizləyirik
//                 return (
//                   normalizedInput.startsWith(normalizedDialCode) ||
//                   normalizedDialCode.startsWith(normalizedInput)
//                 );
//               });
//             }}
//           /> */}

//           {/* <PhoneInput
//             country={"az"}
//             value={this.state.phone}
//             name="phone"
//             onBlur={this.handleValidation}
//             onChange={(phone) => this.setState({ phone })}
//             defaultErrorMessage="It doesn't works, why?"
//             isValid={(value, country) => {
//               if (value.match(/12345/)) {
//                 return "Invalid value: " + value + ", " + country.name;
//               } else if (value.match(/1234/)) {
//                 return false;
//               } else {
//                 return true;
//               }
//             }}
//             inputProps={{
//               name: "phone",
//               required: true,
//             }}
//           /> */}
//           {/* <Controller
//             name="phoneNumber"
//             control={control}
//             defaultValue=""
//             render={({ field }) => (
//               <input type="text" {...field} placeholder="Telefon Numarası" />
//             )}
//           />
//           {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}

//           <select name="country" ref={control.register} defaultValue="US">
//             <option value="US">United States</option>
//             <option value="CA">Canada</option>
//           </select>
//           <p>{formattedNumber}</p> */}
//         </div>
//         <div className="form-group phone-number">
//           <label>Telefon nömrəsi</label>
//           <select
//             name="phoneCode"
//             id="phoneCode"
//             value={selectedValue}
//             onChange={(e) =>{ setPhone(e.target.value)
//             setSelectedValue(e.target.value)}}
//           >
//             <option value="+994">+994</option>
//             <option value="+1">+1</option>
//           </select>
//           <input
//             type="text"
//             // defaultValue={phone}
//             value={phone}
//             onChange={handlePhoneNumberChange}
//             placeholder="Telefon numarası girin"
//           />
//           {isValid ? (
//             <p>Geçerli bir telefon numarası.</p>
//           ) : (
//             <p>Geçerli değil.</p>
//           )}
//         </div>
//         <button className="btn">Qeydiyyat</button>
//         <p className="text">
//           Artıq hesabınız var?
//           <span
//             className="sing-up-link"
//             onClick={() => {
//               setForm("sing in");
//             }}
//           >
//             Daxil ol
//           </span>
//         </p>
//       </div>
//     </form>
//   );
// };

// export default SingUp;
